import { Routes, Route } from "react-router-dom";
import Pages from "./pages";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="/sign-up" element={<Pages.SignUpPage />} />
      <Route path="forgot-password" element={<Pages.ForgotPasswordPage />} />
      <Route path="verify-otp" element={<Pages.VerifyOtp />} />
      <Route path="reset-password" element={<Pages.ResetPassword />} />
      <Route path="change-password" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/profile-setup" element={<Pages.ProfileSetup />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/settings" element={<Pages.ManageCms />} />

      <Route path="/analytics" element={<Pages.Analytics />} />

      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />

      <Route
        path="/manage-advertisements"
        element={<Pages.ManageAdvertisements />}
      />
      <Route
        path="/manage-advertisements/details/:id"
        element={<Pages.AdvertisementDetails />}
      />
      <Route
        path="/manage-advertisements/add"
        element={<Pages.AddAdvertisement />}
      />
      <Route
        path="/manage-advertisements/edit/:id"
        element={<Pages.AddAdvertisement />}
      />
      <Route path="/help-and-support" element={<Pages.CustomerSupport />} />
      <Route
        path="/help-and-support/details/:id"
        element={<Pages.QueryDetails />}
      />
      <Route path="/help-and-support/add" element={<Pages.GenerateTicket />} />
      <Route
        path="/terms-and-condition"
        element={<Pages.TermsAndCondition />}
      />
      <Route path="/about-us" element={<Pages.AboutUs />} />
      <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
      <Route
        path="/manage-advertisements/payment-success"
        element={<Pages.PaymentSuccess />}
      />
    </Routes>
  );
};

export default Routing;
