import { END_POINTS } from "../constants/url";
import { CmsResponse, FaqResponse } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetFaqResponse={
    count:number;
    data:FaqResponse[]

}

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
       getCms: builder.query<CommonResponseType & { data: CmsResponse[] }, {}>({
      query: () => ({
        url: END_POINTS.cms,
        method: "GET",
      }),
    }),
    getFaq: builder.query<CommonResponseType & { data: GetFaqResponse }, {}>({
        query: () => ({
          url: END_POINTS.faq,
          method: "GET",
        }),
      }),
   
  }),
});

export const {
useLazyGetCmsQuery,
useLazyGetFaqQuery
} = cmsApi;
