import React, { useEffect, useState } from 'react'
// import Logo from "../../../public/static/images/logo.png"
import { Container } from '@mui/material'
import { useLazyGetCmsQuery } from '../../services/cms';
import { CmsResponse } from '../../types/General';
import useTranslation from '../../hooks/Translation';
function TermsAndCondition() {

  const [cmsData, setCmsData] = useState<CmsResponse>();
  const translation = useTranslation() as any;
  const [getCms, { isLoading }] = useLazyGetCmsQuery();
  const fetchCmsData = async () => {
    try {
      const response = await getCms({}).unwrap();

      if (response?.statusCode === 200) {
        setCmsData(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  useEffect(() => {
    fetchCmsData();
  }, []);
  return (
    <div>
      <div className='Terms'>
        <figure className='img-figure'>
          <img className='logo-png' src="/static/images/logo.png" alt="" height={100} width={300} />

        </figure>
      </div>
      <h2 className='Terms-heading'>{translation.manage_cms.terms}</h2>
      <Container maxWidth="lg">
        {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Saepe ipsum, expedita nostrum voluptatem corporis velit odio magnam, rem, fuga dolorum tenetur quasi debitis veniam! Inventore accusantium minima, iste veritatis voluptate error aut. Doloremque eligendi pariatur rem asperiores tenetur eum quia ab aspernatur porro expedita fugit enim minus alias, harum ad! */}

        <p
          className={cmsData ? "about_txt" : "about_txt2"}
          dangerouslySetInnerHTML={{
            __html: cmsData?.termsAndConditions
              ? cmsData?.termsAndConditions
              : "No information Found",
          }}
        />
      </Container>
    </div>
  )
}

export default TermsAndCondition
