import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetAdvertisementQuery } from "../../services/main";
import { showError } from "../../constants";
import useTranslation from "../../hooks/Translation";
import { AdvertisementResponse } from "../../types/General";
import moment from "moment";
import { Upload } from "@mui/icons-material";

const AdvertisementDetails = () => {
  const { id } = useParams();
  const [advertisement, setAdvertisement] = useState<AdvertisementResponse[]>(
    []
  );
  const [open, setOpen] = useState<boolean>(false);
  const translation = useTranslation() as any;
  const [uploadPreview, setUploadPreview] = useState<string>("");
  const [type, setType] = useState("");
  const [getAdvertisementById, { isLoading }] = useLazyGetAdvertisementQuery();
  const handlePopup = (image: string, type: string) => {
    setOpen(true);
    setUploadPreview(image);
    setType(type);
  };
  const advertisementById = async (id: string) => {
    try {
      console.log("id", id);
      const response: any = await getAdvertisementById({
        id: id,
      });

      if (response?.data?.statusCode === 200) {
        console.log("hello");
        setAdvertisement(response?.data?.data.advertisements || []);
      } else {
        setAdvertisement([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      advertisementById(id);
    }
  }, []);
  console.log("add", advertisement);
  const navigate = useNavigate();
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translation.manage_advertisements.view_details}
          </h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-advertisements");
            }}
          >
            {translation.manage_advertisements.back}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box view_box_list">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                {advertisement[0]?.uploads[0]?.type === "VIDEO" ? (
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="hm_vd"
                    preload="yes"
                    key={advertisement[0]?._id}
                  >
                    <source
                      src={
                        advertisement[0]?.uploads[0]?.link ||
                        "/static/images/post.png"
                      }
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <figure className="banner_img">
                    <img
                      src={
                        advertisement[0]?.uploads[0]?.link ||
                        "/static/images/post.png"
                      }
                      alt=""
                    />
                  </figure>
                )}
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} />
              {/* <Grid item xs={10} className="view_box_list"> */}
              {/* <Grid container spacing={3}> */}
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translation.manage_advertisements.advertisement_Title}
                  </Typography>
                  <Typography component="p">
                    {advertisement[0]?.title}
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translation.manage_advertisements.advertisement_Status}
                  </Typography>
                  <Typography component="p">
                    {advertisement[0]?.status}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translation.manage_advertisements.web_Link}
                  </Typography>
                  <Typography component="p">
                    <a
                      href={advertisement[0]?.webLink}
                      target="_blank"
                      rel="noreffer"
                    >
                      {advertisement[0]?.webLink}
                    </a>
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    {translation.manage_advertisements.created}
                  </Typography>
                  <Typography component="p">
                    {moment(advertisement[0]?.createdAt).format("DD/MM/YY")}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.manage_advertisements.Hours}
                      </Typography>
                      <Typography component="p">
                        {advertisement[0]?.noOfHours}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.manage_advertisements.hourly_charge_for_ad}
                      </Typography>
                      <Typography component="p">
                        ${advertisement[0]?.HourlyCharge}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translation.manage_advertisements.total_cost}
                      </Typography>
                      <Typography component="p">
                        ${advertisement[0]?.totalCost}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AdvertisementDetails;
