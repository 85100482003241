import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import useTranslation from "../../hooks/Translation";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetQueriesIdQuery } from "../../services/main";
import { showError } from "../../constants";

const QueryDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams()
  const [getQueries, { isLoading }] = useLazyGetQueriesIdQuery()
  const [queries, setQueries] = useState<any>()
  const translation = useTranslation() as any;
  const fetchQueries = async (id: string | undefined) => {
    try {
      const response: any = await getQueries({
        id
      })
      if (response?.data?.statusCode === 200) {
        console.log("okayyy")
        setQueries(response?.data?.data)
        // setTotalCount(response?.data?.data?.count)
      }
      console.log(response, "response")
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }

  const location = useLocation();

  useEffect(() => {
    if (id) {

      fetchQueries(id)
    }
  }, [])

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>{translation.help_support.querydetails}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/help-and-support");
            }}
          >
            {translation.help_support.back}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box className="heading">
                      <Typography component="h2">{translation.help_support.userdetails}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">{translation.help_support.username}</Typography>
                      <Typography component="p">{queries?.userName}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">{translation.help_support.mail}</Typography>
                      <Typography component="p">{queries?.email}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">{translation.help_support.contact}</Typography>
                      <Typography component="p">
                        {translation.help_support.keymissing}
                        {/* {" "}
                        {queryDetail?.countryCode
                          ? (queryDetail?.countryCode.includes("+")
                              ? ""
                              : "+") + queryDetail?.countryCode
                          : null}{" "}
                        {queryDetail?.phone || "-"} */}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="heading">
                      <Typography component="h2">{translation.help_support.querydetails}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">{translation.help_support.issue}</Typography>
                      <Typography component="p">{queries?.issueType}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">{translation.help_support.querystatus}</Typography>
                      <Typography component="p">{queries?.status === 0 ? "Pending" : "Resolved"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">{translation.help_support.Message}</Typography>
                      <Typography component="p">
                        {queries?.description}
                      </Typography>
                    </Box>
                  </Grid>

                  {queries?.reply?.length !== 0 ? (
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">{translation.help_support.revert}</Typography>
                        <Typography component="p">
                          {queries?.reply}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : null}


                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default QueryDetails;
