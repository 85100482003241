// Staging Url ----
// export const API_URL =
//   "https://apistagebucket.appgrowthcompany.com/api/v1/advertiser/";
export const API_URL =
  "https://testapi.ambitio.ae/api/v1/advertiser/";

// Development url
// export const API_URL =
//   "https://apibucket.appgrowthcompany.com/api/v1/advertiser/";

export const STRIPE_TEST_KEY =
  "sk_test_51Ovv6ESJpEGGBqLruSHlebVWfJGZQs9xSBwkO2NfufhL2bRPjgXeZp8WYeKqDGxCTsqkHOuCxAxoAaia7uSfKXF700s1IdxzwW";

export const END_POINTS = {
  signUp: "register",
  login: "login",
  forgotPassword: "forgotpassword",
  get_user: "users",
  update_profile: "updateProfile",
  profileOTP: "profileOtp",
  verifyProfileOtp: "verifyProfileOtp",
  mediaUpload: "upload",
  verifyOtp: "verifyotp",
  resetPassword: "setPassword",
  changePassword: "changepassword",
  logout: "logout",

  //cms
  cms: "cms",
  faq: "faq",

  // advertisement
  addAdvertisement: "addAdvertisement",
  getAdvertisement: "getAdvertisement",
  deleteAdvertisement: "deleteAdvertisement",
  blockUnblockAdvertisement: "blockUnblockAdvertisement",
  editAdvertisement: "editAdvertisement",

  socialLogin: "socialLogin",
  getQueries: "getQueries",
  deleteQueries: "deleteQueries",
  getQueriesById: "getQueriesById",
  genereateTicket: "genereateTicket",
  dashboard: "dashboard",
  getTotalAdGraph: "getTotalAdGraph",
  getTotalPaymentsGraph: "getTotalPaymentsGraph",
  getTotalActiveAdGraph: "getTotalActiveAdGraph",
  adPayment: "adPayment",
  notificationListing:"notificationListing"
};
