import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import { FormControl, Grid, Button, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import FetchAdsData from "../../utils/graphFunctions";
// ChartJS.register(ArcElement, Tooltip, Legend);
import useTranslation from "../../hooks/Translation";

const Analytics = () => {
  const { adsData, fetchAdGraphData, paymentData, fetchPaymentGraphData, activeAdsData, fetchActive } = FetchAdsData()
  const [studentFilter, setStudentFilter] = useState<string>("Monthly");
  const [earningFilter, setEarningFilter] = useState<string>("Monthly");
  // const [adFilter, setAdFilter] = useState<string>("Monthly");
  const [activeAd, setActiveAd] = useState<string>("Monthly");
  const translation = useTranslation() as any;
  const handleOrderChange = (event: SelectChangeEvent) => {
    setStudentFilter(event.target.value as string);

  };
  const handleEarningChange = (event: SelectChangeEvent) => {
    setEarningFilter(event.target.value as string);
  };
  const handleActiveAd = (event: SelectChangeEvent) => {
    setActiveAd(event.target.value as string);
  };
  const graphAds = {
    labels: adsData.label,
    datasets: [
      {
        label: translation.report_analytics.ads,
        data: adsData.dataSet,
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };


  const graphActiveAds = {
    labels: activeAdsData.label,
    datasets: [
      {
        label: translation.report_analytics.active,
        data: activeAdsData.dataSet,
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };

  const graphUsersData = {
    labels: paymentData.label,
    datasets: [
      {
        label: translation.report_analytics.payment,
        data: paymentData.dataSet,
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };
  useEffect(() => {
    fetchAdGraphData(studentFilter)
  }, [studentFilter])
  useEffect(() => {
    fetchPaymentGraphData(earningFilter)
  }, [earningFilter])
  useEffect(() => {
    fetchActive(activeAd)
  }, [activeAd])
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.report_analytics.heading}</h1>
          {/* <Button className="btn btn_primary">{translation.report_analytics.csv}</Button> */}
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                {translation.report_analytics.total_Ads}
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studentFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleOrderChange}
                  >
                    <MenuItem value="" disabled>
                      {translation.report_analytics.select}
                    </MenuItem>
                    <MenuItem value="Daily">{translation.report_analytics.daily}</MenuItem>
                    <MenuItem value="Weekly">{translation.report_analytics.weekly}</MenuItem>
                    <MenuItem value="Monthly">{translation.report_analytics.monthly}</MenuItem>
                    <MenuItem value="Yearly">{translation.report_analytics.yearly}</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphAds} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                {translation.report_analytics.active_Ads}
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={activeAd}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleActiveAd}
                  >
                    <MenuItem value="" disabled>
                      {translation.report_analytics.select}
                    </MenuItem>
                    <MenuItem value="Daily">{translation.report_analytics.daily}</MenuItem>
                    <MenuItem value="Weekly">{translation.report_analytics.weekly}</MenuItem>
                    <MenuItem value="Monthly">{translation.report_analytics.monthly}</MenuItem>
                    <MenuItem value="Yearly">{translation.report_analytics.yearly}</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphActiveAds} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                {translation.report_analytics.payments}
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={earningFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleEarningChange}
                  >
                    <MenuItem value="" disabled>
                      {translation.report_analytics.select}
                    </MenuItem>
                    <MenuItem value="Daily">{translation.report_analytics.daily}</MenuItem>
                    <MenuItem value="Weekly">{translation.report_analytics.weekly}</MenuItem>
                    <MenuItem value="Monthly">{translation.report_analytics.monthly}</MenuItem>
                    <MenuItem value="Yearly">{translation.report_analytics.yearly}</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphUsersData} />
            </div>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Analytics;
