import { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MainContainer from "../../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { usePostChangePasswordMutation } from "../../../services/auth";
import { showError, showToast } from "../../../constants";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<boolean>(false);
  const [cnfmPassword, setCnfmPassword] = useState<boolean>(false);
  const [changePasswordMutation] = usePostChangePasswordMutation();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
      oldPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required("Old Password is required!")
        .min(6, "Password must be at least 6 characters.")
        .matches(
          /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. "
        ),

      newPassword: Yup.string()
        .label("Password")
        .required("Password is required!")
        .min(6, "Password must be at least 6 characters.")
        .matches(
          /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. "
        )
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "New password cannot be same as old password."
        ),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let body = {
        oldPassword: formik.values.oldPassword,
        password: formik.values.newPassword,
        appKey: new Date().toISOString(),
      };

      try {
        const response = await changePasswordMutation(body).unwrap();
        if (response?.statusCode === 200) {
          showToast("Password changed successfully" || "");
          navigate("/dashboard");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
      formik.setSubmitting(false);
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword1 = () => {
    setOldPassword(!oldPassword);
  };
  const handleMouseDownPassword1 = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setCnfmPassword(!cnfmPassword);
  };
  const handleMouseDownPassword2 = (event: any) => {
    event.preventDefault();
  };

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Change Password</h1>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: "8px !important" }}>
                <Grid sx={{ maxWidth: 350 }} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">
                      Current Password
                    </Typography>
                    <TextField
                      hiddenLabel
                      placeholder="Current Password"
                      fullWidth
                      name="oldPassword"
                      className="text_field"
                      type={oldPassword ? "text" : "password"}
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.oldPassword}
                      helperText={
                        formik.touched.oldPassword && formik.errors.oldPassword
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="eye_btn" position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword1}
                              onMouseDown={handleMouseDownPassword1}
                              edge="end"
                            >
                              {oldPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="custom_label">
                      New Password
                    </Typography>
                    <TextField
                      hiddenLabel
                      placeholder="New Password"
                      className="text_field"
                      fullWidth
                      name="newPassword"
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.newPassword}
                      helperText={
                        formik.touched.newPassword && formik.errors.newPassword
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="eye_btn" position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="custom_label">
                      Confirm Password
                    </Typography>
                    <TextField
                      hiddenLabel
                      placeholder="Confirm Password"
                      fullWidth
                      name="passwordConfirmation"
                      className="text_field"
                      type={cnfmPassword ? "text" : "password"}
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.passwordConfirmation}
                      helperText={
                        formik.touched.passwordConfirmation &&
                        formik.errors.passwordConfirmation
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="eye_btn" position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword2}
                              onMouseDown={handleMouseDownPassword2}
                              edge="end"
                            >
                              {cnfmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default ChangePassword;
