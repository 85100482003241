import React from "react";
import MainContainer from "../../layout/MainContainer";
import { DashBoard } from "../../features";

const DashBoardPage = () => {
  return (
    <MainContainer>
      <DashBoard />
    </MainContainer>
  );
};

export default DashBoardPage;
