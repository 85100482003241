export const en = {
  Globals: {
    Login: "Login",
    Continue: "Continue",
    sign_in: "Sign In",
    sign_up: "Sign Up",
    dashboard: "Dashboard",
    manage_advertisements: "Manage Advetisements",
    help_support: "Help and Support",
    report_analytics: "Report and Analytics",
    settings: "Settings",
    faq: "FAQ's",
    min_3:"Minimum 3 characters are required",
    min_16:"Minimum 16 numbers are required",
    card_details:"Enter card details",
    card_holder_name:"Card Holder Name",
    card_number:"Card Number",
    expiry:"Expiry Date",
    CVV:"CVV"

  },
  log_in: {
    sign_in: "Sign In to Bucketlist",
    or_use_email: "Or use your email for registration:",
    toggle_pass: "toggle password visibility",
    password: "password",
    Password: "Password",
    password_req: "Password is required!",
    password_atleast_6: "Password must be at least 6 characters.",
    phone_num: "Phone Number",
    phone_req: "Phone number is required",
    phone_atleast6: "Phone number must be at least 6 digits",
    phone_atleast16: "Phone number must be at least 16 digits",
    enter_valid_email: "Please enter valid email",
    forgot_pw: "Forgot Password",
    login: "login",
    or_use: "Or use your",
    phone: "phone",
    email: "email",
    Email: "Email",
    remember_me: "Remember me",
    remember: "remember",
    body: "body",
    country_code: "countryCode",
    email_req: "Email is required",
    log_in: "Login",
    sign_up: "Sign up",
    heading: "Hello There, Join Us",
    desc: "Enter your personal details and join the ambitio community",
    search: "Search here",
  },
  sign_in: {
    email_req: "Email is required",
    enter_valid_email: "Please enter valid email",
    Password: "Password",
    password: "password",
    phone_num: "phone number",
    Phone: " Phone Number",
    password_req: "Password is required!",
    password_atleast_6: "Password must be at least 6 characters.",
    password_validation:
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character.",
    password_match: "Passwords must match.",
    confirm_pass_msg: "Confirm password is required.",
    phone_req: "Phone number is required",
    phone_atleast6: "Phone number must be at least 6 digits",
    phone_atleast16: "Phone number must be at least 16 digits",
    create_ac: "Create an Account",
    email: " Email Address",
    toggle_pass: "toggle password visibility",
    confirm_pass: "Confirm Password",
    sign_up: "Sign up",
    welcome: "Welcome Back",
    warning: "Already signed up, enter your details and start exploring",
    signin: "Sign In",
  },

  dashboard: {
    total_Ads: "Total Ads",
    active_Ads: "Total Active Ads",
    payments: "Total Payments",
    total_add: "Total Ads",
    select: "Select",
    daily: "Daily",
    monthly: "Monthly",
    weekly: "Weekly",
    yearly: "Yearly",
    ads: "Ads",
    payment: "Payments",
    dashboard: "Dashboard",
  },

  forgot_pass: {
    email_req: "Email is required",
    enter_valid_email: "Enter a valid email address!",
    forgot_pass: "Forgot Password",
    email: "Email Address",
    send: "Send",
    welcome: "Welcome Back",
    warning: "Already signed up, enter your details and start exploring",
    signin: "Sign In",
  },

  manage_advertisements: {
    accepted: "Accepted",
    pending: "Pending",
    approved: "Approved",
    rejected: "Rejected",
    add_ad: "Add Advertisement",
    edit_ad: "Edit Advertisement",
    s_no: "S.No",
    back: "Back",
    advertisement_Image: " Advertisement Image",
    advertisement_Title: "Advertisement Title",
    advertisement_Status: "Advertisement Status",
    no_ad: "No Advertisements Found",
    web_Link: "Web Link",
    hours: "No. of hours",
    Hours: "Number of hours",
    hourly_charge: "Hourly charge",
    total_cost: "Total Cost",
    save: "Save",
    bnr_img: "Banner Image",
    created: "Created At",
    hourly_charge_for_ad: "Hourly charge for ad",
    total: "Total Cost",
    link: "Web Link",
    created_date: "Created Date",
    status: "Status",
    field_req: "This field is required",
    Actions: "Actions",
    error1: "Could not create canvas context",
    error2: "Please select a valid image file (png or jpeg).",
    error3: "Please upload image or video",
    warning1: "Maximum 60 character are allowed",
    warning2: "Minimum 2 characters are required",
    update_Toast: "Advertisement Updated Successfully!",
    add_Toast: "Advertisement Added Successfully!",
    view_details: "View Advertisement Details",
    manage_ad: "Manage Advertisements",
  },
  help_support: {
    heading: "Help and Support",
    resolved: "Queries Resolved",
    pending: "Queries Pending",
    ticket: "Generate Ticket",
    s_no: "S.No",
    back: "Back",
    date: "Date",
    username: "UserName",
    mail: "Email",
    issue: "Issue Type",
    action: "Action",
    no_query: "No Queries Found",
    name: "Name is required",
    min2: "Minimum 2 characters are required",
    max10: "Maximum 10 characters are allowed",
    max80: "Maximum 80 characters are allowed",
    type: "Type is required",
    msg: "Message is required",
    email_req: "Email is required",
    enter_valid_email: "Enter a valid email address!",
    ticket_toast: "Ticket Generated",
    Name: "Name",
    Message: "Message",
    submit: "Submit",
    userdetails: "User Details",
    querydetails: "Query Details",
    contact: "Contact No",
    keymissing: "key missing in response",
    querystatus: "Query Status",
    revert: "Revert",
  },

  report_analytics: {
    csv: "Export Csv",
    total_Ads: "Total Ads",
    active_Ads: "Total Active Ads",
    active: "Active Ads",
    payments: "Total Payments",
    select: "Select",
    monthly: "Monthly",
    weekly: "Weekly",
    yearly: "Yearly",
    daily: "Daily",
    ads: "Ads",
    payment: "Payments",
    heading: "Reports and Analytics",
  },

  settings: {
    settings: "Settings",
    manage_fee: "Manage Transaction Fee",
    transaction_fee: "Transaction Fee",
    manage_savings: " Manage Transfer Savings",
    date: " Date of Transfer",
    enable_disable: "Enable/Disable Apis",
    ACHQ: "ACHQ",
    plaid: "Plaid",
    save: "Save",
  },

  manage_cms: {
    settings: "Settings",
    contact: "Contact Us",
    terms: "Terms and Conditions",
    about: "About the Platform",
    email: "Email",
    phone: " Phone Number",
  },
  about_us: {
    about: "About US",
    msg: "No information Found",
  },
  faq: {
    manage: "Manage Faqs",
    add: "Add Faq",
    error: "No faq's listing found",
    faq: "Faqs",
  },
  notification: {
    manage: "Manage Notifications",
    add: "Add Notification",
    s_no: "S.No",
    date: "Date",
    title: "Title",
    message: "Message",
    delete: "Delete",
    back: "Back",
    not_title: "Notification Title",
    not_msg: "Notification Message",
    select_rec: "Select Receiver",
    select: "Select",
    all: "All",
    save: "Save",
    receive: "Recieved Notifications",
    name: "Customer Name",
    email: "Customer Email",
    action: "Action",
  },
  privacy: {
    policy: "Privacy Policy",
    msg: "No information Found",
  },
  profile: {
    otp_toast: "OTP resent successfully",
    email_toast: "Please verify email first ",
    phone_toast: "Please verify phone number first ",
    profile_created: "Profile created successfully!",
    update_toast: "Profile updated succesfully",
    email_req: "Email is required",
    error1: "Document front image is required",
    error2: "Document back image is required",
    enter_valid_email: "Enter a valid email address!",
    full_name: "Full name",
    full_name_err: "Full name is required",
    min2: "Minimum 2 characters are required",
    max80: "Maximum 80 characters are allowed",
    phone_req: "Phone number is required",
    min6: "Phone number must be at least 6 characters",
    max16: "Phone number must be at least 16 characters",
    address_req: "Address is required",
    max100: "Maximum 100 character are allowed",
    city_req: "City/State is required",
    counrty: "Country is required",
    field: "This field is required",
    min4: "Minimum 4 characters are required",
    max12: "Maximum 12 character are allowed",
    edit_pro: "Edit Profile",
    img: "Image",
    profile: "Profile",
    name: "Full Name",
    email: "Email",
    verify: "Verify",
    phone: " Phone Number",
    address: "Address",
    city: "City",
    state: "State",
    place: "City/State",
    country: "Country",
    pin: "PIN Code/Zipcode",
    upload: "Upload Documents",
    doc: "Name of the Document",
    date: "Expiry Date",
    doc_img1: " Document Front Image",
    doc_img2: " Document Back Image",
    submit: "Submit",
    verify_otp: "Verify OTP",
    resend: "Resend OTP",
    msg: "The verification code will expire in",
  },

  sidebar:{
    dashboard:"Dashboard",
    manage_advertisement:"Manage Advertisement",
    help_support:"Help and Support",
    report_analytics:"Report and analytics",
    settings:"Settings",
    faq:"Faq's"
  },
  search:"Search here",
  showing:"Showing",
  items:"items",
  myProfile:"My Profile",
  change_password:"Change Password",
  logout:"Logout",
  select_language:"Select language",
  hello_there:"Hello There, Join Us",
  message_page:"Enter your personal details and join the ambitio community"
  

};
