import React, { useState } from "react";
import {
  useLazyActiveGraphQuery,
  useLazyAdsGraphQuery,
  useLazyPaymentsGraphQuery,
} from "../services/main";
import { showError } from "../constants";

function FetchAdsData() {
  const [adsData, setAdsData] = useState<any>({ label: [], dataSet: [] });
  const [paymentData, setPaymentData] = useState<any>({
    label: [],
    dataSet: [],
  });
  const [activeAdsData, setActiveAdsData] = useState<any>({
    label: [],
    dataSet: [],
  });

  const [getAds] = useLazyAdsGraphQuery();
  const [getPayments] = useLazyPaymentsGraphQuery();
  const [getActiveAds] = useLazyActiveGraphQuery();
  const fetchAdGraphData = async (studentFilter: string) => {
    try {
      const response: any = await getAds({
        type: studentFilter,
      });
      if (response?.data?.statusCode === 200) {
        const newSet = response?.data?.data;
        const label: string[] = [];
        const data: string[] = [];

        newSet.forEach((item: any) => {
          label.push(item.name);
          data.push(item.value);
        });
        setAdsData({ label: label, dataSet: data });
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const fetchPaymentGraphData = async (earningFilter: string) => {
    try {
      const response: any = await getPayments({
        type: earningFilter,
      });
      if (response?.data?.statusCode === 200) {
        const newSet = response?.data?.data;
        const label: string[] = [];
        const data: string[] = [];

        newSet.forEach((item: any) => {
          label.push(item.name);
          data.push(item.value);
        });
        setPaymentData({ label: label, dataSet: data });
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const fetchActive = async (active: string) => {
    try {
      const response: any = await getActiveAds({
        type: active,
      });
      if (response?.data?.statusCode === 200) {
        const newSet = response?.data?.data;
        const label: string[] = [];
        const data: string[] = [];

        newSet.forEach((item: any) => {
          label.push(item.name);
          data.push(item.value);
        });
        setActiveAdsData({ label: label, dataSet: data });
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  return {
    adsData,
    paymentData,
    fetchAdGraphData,
    fetchPaymentGraphData,
    activeAdsData,
    fetchActive,
  };
}

export default FetchAdsData;
